import { createSlice } from '@reduxjs/toolkit';

import moment from 'moment';
import { Constants } from '../../const';

const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
const today = moment().format('YYYY-MM-DD');
const { SEARCH_TYPES, SCREEN_TYPES } = Constants.CommonConst;

const initialState = {
  isOngoingMonitoringTableView: false,
  isInitialScreeningTableView: false,
  searchId: '',
  searchType: '',
  alertListType: [],
  alerted: '',
  targetStartDate: '',
  targetEndDate: '',
  status: '',
  alert: 20,
  bookmark: false,
  team: '',
  manager: '',
  analyst: '',
  horizontalTeam: '',
  ongoingsearchId: '',
  ongoingsearchType: '',
  ongoingalertListType: [],
  ongoingalerted: '',
  ongoingtargetStartDate: '',
  ongoingtargetEndDate: '',
  ongoingstatus: '',
  ongoingalert: 20,
  ongoingbookmark: false,
  ongoingteam: '',
  ongoingmanager: '',
  ongoinganalyst: '',
  ongoinghorizontalTeam: '',
  initialPageSizeInitialScreening: 10,
  initialPageIndexInitialScreening: 0,
  initialPageSizeOngoingMonitoring: 10,
  initialPageIndexOngoingMonitoring: 0,
  channel: '',
  product: '',
  transaction_type: '',
  ongoingchannel: '',
  ongoingproduct: '',
  ongoingtransaction_type: '',
  downloadReportFilters: {
    screening_type: SCREEN_TYPES.i.key,
    dates: { Start_Date: startOfMonth, End_Date: today },
    alerted: ['Alert'],
    listType: [],
    status: ['Accepted'],
    search_type: SEARCH_TYPES.i.key,
    team: '',
    analyst_id: '',
    manager_id: '',
    baseStr: '',
    risk_type: [],
  },
  historyFilters: {
    alerted: '',
    searchType: '',
    riskType: '',
    finalVerdict: '',
    team: '',
    analyst: '',
    initiatedStartDate: '',
    initiatedEndDate: '',
    verdictStartDate: '',
    verdictEndDate: '',
    screeningType: 'OngoingScreening',
  },
  downloadStatusFilters: {
    screening_type: SCREEN_TYPES.i.key,
    dates: { start_date: startOfMonth, end_date: today },
    search_type: SEARCH_TYPES.i.key,
    team: '',
    due_date: today,
    current_status: '',
    pending_with: '',
    final_verdict: '',
    manager_id: '',
  },
  riskRatingDashboardFilters: {
    screen_type: SCREEN_TYPES.o.key,
    search_type: '',
    start_date: '',
    end_date: '',
    duration: '',
    analyst: null,
  },
  exclusionListFilter: {
    filter_type: '',
    batchID: '',
    search_type: '',
    listType: [],
  },
  addtoExclusionFilter: {
    filterType: '',
    Start_Date: '',
    search_type: '',
    End_Date: '',
    listType: [],
  },
  dashboardFilters: {
    manager_id: '',
    team: '',
    StartDate: '',
    EndDate: '',
    analyst: '',
    screen_type: 'InitialScreening',
    seach_initiated: '',
  },
};
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    ongoingMonitoringTableView: (state) => {
      state.isOngoingMonitoringTableView = !state.isOngoingMonitoringTableView;
    },
    initialScreeningTableView: (state) => {
      state.isInitialScreeningTableView = !state.isInitialScreeningTableView;
    },
    setInitialPageSizeInitialScreening: (state, action) => {
      state.initialPageSizeInitialScreening = action.payload
        ? action.payload
        : state.initialPageSizeInitialScreening;
    },
    setInitialPageIndexInitialScreening: (state, action) => {
      state.initialPageIndexInitialScreening = action.payload
        ? action.payload
        : state.initialPageIndexInitialScreening;
    },
    resetInitialPageIndexInitialScreening: (state) => {
      state.initialPageIndexInitialScreening = 0;
    },
    resetInitialPageSizeInitialScreening: (state) => {
      state.initialPageIndexInitialScreening = 10;
    },
    setInitialPageSizeOngoingMonitoring: (state, action) => {
      state.initialPageSizeOngoingMonitoring = action.payload
        ? action.payload
        : state.initialPageSizeOngoingMonitoring;
    },
    setInitialPageIndexOngoingMonitoring: (state, action) => {
      state.initialPageIndexOngoingMonitoring = action.payload
        ? action.payload
        : state.initialPageIndexOngoingMonitoring;
    },
    resetInitialPageIndexOngoingMonitoring: (state) => {
      state.initialPageIndexOngoingMonitoring = 0;
    },
    resetInitialPageSizeOngoingMonitoring: (state) => {
      state.initialPageSizeOngoingMonitoring = 10;
    },
    setDownloadReportFilters: (state, action) => {
      state.downloadReportFilters = action.payload
        ? action.payload
        : initialState.downloadReportFilters;
    },
    removeDownloadReportFilters: (state) => {
      state.downloadReportFilters = initialState.downloadReportFilters;
    },
    setHistoryFilters: (state, action) => {
      state.historyFilters = action.payload
        ? action.payload
        : initialState.historyFilters;
    },
    setDownloadStatusFilters: (state, action) => {
      state.downloadStatusFilters = action.payload
        ? action.payload
        : initialState.downloadStatusFilters;
    },
    setRiskRatingDashboardFilters: (state, action) => {
      state.riskRatingDashboardFilters = action.payload
        ? action.payload
        : initialState.riskRatingDashboardFilters;
    },
    setExclusionListFilter: (state, action) => {
      state.exclusionListFilter = action.payload
        ? action.payload
        : initialState.exclusionListFilter;
    },
    setAddToExclusionListFilter: (state, action) => {
      state.addtoExclusionFilter = action.payload
        ? action.payload
        : initialState.addtoExclusionFilter;
    },
    setDashboardFilters: (state, action) => {
      state.dashboardFilters = action.payload
        ? action.payload
        : initialState.dashboardFilters;
    },
    screenTypeData: (state, action) => {
      (state.searchId = action?.payload?.searchId),
        (state.searchType = action?.payload?.searchType),
        (state.alertListType = action?.payload?.alertListType),
        (state.alerted = action?.payload?.alerted),
        (state.targetStartDate = action?.payload?.targetStartDate),
        (state.targetEndDate = action?.payload?.targetEndDate),
        (state.status = action?.payload?.status),
        (state.alert = action?.payload?.alert),
        (state.bookmark = action?.payload?.bookmark),
        (state.team = action?.payload?.team),
        (state.analyst = action?.payload?.analyst),
        (state.manager = action?.payload?.manager),
        (state.horizontalTeam = action?.payload?.horizontalTeam),
        (state.channel = action?.payload?.channel),
        (state.product = action?.payload?.product),
        (state.transaction_type = action?.payload?.transaction_type);
    },
    removeScreenTypeData: (state) => {
      (state.searchId = ''),
        (state.searchType = ''),
        (state.alertListType = []),
        (state.alerted = ''),
        (state.targetStartDate = null),
        (state.targetEndDate = null),
        (state.status = ''),
        (state.alert = 20),
        (state.bookmark = ''),
        (state.team = ''),
        (state.analyst = ''),
        (state.horizontalTeam = ''),
        (state.manager = ''),
        (state.channel = ''),
        (state.product = ''),
        (state.transaction_type = '');
    },
    ongoingScreenTypeData: (state, action) => {
      (state.ongoingsearchId = action?.payload?.ongoingsearchId),
        (state.ongoingsearchType = action?.payload?.ongoingsearchType),
        (state.ongoingalertListType = action?.payload?.ongoingalertListType),
        (state.ongoingalerted = action?.payload?.ongoingalerted),
        (state.ongoingtargetStartDate =
          action?.payload?.ongoingtargetStartDate),
        (state.ongoingtargetEndDate = action?.payload?.ongoingtargetEndDate),
        (state.ongoingstatus = action?.payload?.ongoingstatus),
        (state.ongoingalert = action?.payload?.ongoingalert),
        (state.ongoingbookmark = action?.payload?.ongoingbookmark),
        (state.ongoingteam = action?.payload?.ongoingteam),
        (state.ongoinganalyst = action?.payload?.ongoinganalyst),
        (state.ongoingmanager = action?.payload?.ongoingmanager),
        (state.ongoinghorizontalTeam = action?.payload?.ongoinghorizontalTeam),
        (state.ongoingchannel = action?.payload?.ongoingchannel),
        (state.ongoingproduct = action?.payload?.ongoingproduct),
        (state.ongoingtransaction_type =
          action?.payload?.ongoingtransaction_type);
    },
    ongoingRemoveScreenTypeData: (state) => {
      (state.ongoingsearchId = ''),
        (state.ongoingsearchType = ''),
        (state.ongoingalertListType = []),
        (state.ongoingalerted = ''),
        (state.ongoingtargetStartDate = null),
        (state.ongoingtargetEndDate = null),
        (state.ongoingstatus = ''),
        (state.ongoingalert = 20),
        (state.ongoingbookmark = ''),
        (state.ongoingteam = ''),
        (state.ongoinganalyst = ''),
        (state.ongoinghorizontalTeam = ''),
        (state.ongoingmanager = ''),
        (state.ongoingchannel = ''),
        (state.ongoingproduct = ''),
        (state.ongoingtransaction_type = '');
    },
  },
});
export const {
  ongoingMonitoringTableView,
  initialScreeningTableView,
  screenTypeData,
  removeScreenTypeData,
  ongoingScreenTypeData,
  ongoingRemoveScreenTypeData,
  setInitialPageIndexInitialScreening,
  setInitialPageSizeInitialScreening,
  resetInitialPageSizeInitialScreening,
  resetInitialPageIndexInitialScreening,
  setInitialPageIndexOngoingMonitoring,
  setInitialPageSizeOngoingMonitoring,
  resetInitialPageSizeOngoingMonitoring,
  resetInitialPageIndexOngoingMonitoring,
  setDownloadReportFilters,
  removeDownloadReportFilters,
  setHistoryFilters,
  setDownloadStatusFilters,
  setRiskRatingDashboardFilters,
  setExclusionListFilter,
  setAddToExclusionListFilter,
  setDashboardFilters,
} = commonSlice.actions;
export default commonSlice.reducer;
