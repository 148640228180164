// @ts-nocheck
/* eslint-disable */
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Utils } from './utils/index';
import useCurrentTheme from './theme/useCurrentTheme';
import { AppRoutes } from './routing/AppRoutes';
import { API_ENDPOINTS } from './const/ApiEndpoints';

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useCurrentTheme();
  Utils.ApiUtils.intializeAxios({ enqueueSnackbar, API_ENDPOINTS });
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
