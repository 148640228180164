/* eslint-disable no-param-reassign */
// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Utils } from '../../utils/index';

const getDataFromLocalStorage = (key) => {
  let dataFromLocalStorage = {};
  try {
    dataFromLocalStorage = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : {};
  } catch (e) {
    return dataFromLocalStorage;
  }
  return dataFromLocalStorage;
};
const initialState = {
  preferences: {
    theme: 'light-theme',
  },
  auth: {
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    token: localStorage.getItem('token'),
    refresh: localStorage.getItem('refreshToken'),
    user: getDataFromLocalStorage('user'),
    panelNavigation: getDataFromLocalStorage('panelNavigation'),
    org: localStorage.getItem('org'),
  },
  status: 'idle',
};
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/changeTheme',
  async (amount) => {
    const response = { data: amount };
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTheme: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    setAuth: (state, action) => {
      state.auth.isLoggedIn = action.payload.isLoggedIn;
      state.auth.token = action.payload.token;
      state.auth.user = action.payload.user;
      state.auth.panelNavigation = action.payload.panelNavigation;
      state.auth.org = action.payload?.org || '';
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('isLoggedIn', action.payload.isLoggedIn);
      localStorage.setItem('refreshToken', action.payload.refresh);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem(
        'panelNavigation',
        JSON.stringify(action.payload.panelNavigation)
      );
      localStorage.setItem('org', action.payload?.org || '');
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state) => {
        state.status = 'idle';
        // state.value += action.payload;
      });
  },
});

export const { setTheme, setAuth } = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state) => state.counter.value;
export const selectActiveTheme = (state) => state.app.preferences.theme;
export const selectAuth = (state) => state.app.auth;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default appSlice.reducer;
