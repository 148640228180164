/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { API_ENDPOINTS } from '../const/ApiEndpoints';

let TOKEN_REFRESH_PENDING = false;
const refreshAccessToken = (refreshToken) => {
  return axios.post(API_ENDPOINTS.REFRESH_TOKEN, {
    refresh: refreshToken,
  });
};
const logout = () => {
  try {
    localStorage.clear();
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};
const intializeAxios = ({ enqueueSnackbar }) => {
  let headersCommonOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let showLoaderEvent = new Event('showLoader', { bubbles: true });
  let hideLoaderEvent = new Event('hideLoader', { bubbles: true });
  let numberOfApiCallsPending = 0;
  axios.defaults.baseURL = API_ENDPOINTS.DJANGO_SERVER_ADDRESS;
  axios.defaults.headers.common = headersCommonOptions;
  axios.interceptors.request.use((req) => {
    // `req` is the Axios request config, so you can modify
    // the `headers`.
    const token = localStorage.getItem('token');
    if (token && !req.url.startsWith('https://')) {
      req.headers.Authorization = 'Bearer ' + token;
    }
    const org = localStorage.getItem('org');
    if (org) {
      req.headers.org = org;
    }
    numberOfApiCallsPending++;
    if (req?.loader == false || req?.data?.loader == false) {
      document.dispatchEvent(hideLoaderEvent);
    } else {
      document.dispatchEvent(showLoaderEvent);
    }
    return req;
  });

  axios.interceptors.response.use(
    (response) => {
      numberOfApiCallsPending--;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 400);
      }
      return response;
    },
    async (error) => {
      numberOfApiCallsPending--;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 400);
      }
      if (error.config.url === API_ENDPOINTS.REFRESH_TOKEN) {
        logout();
        return Promise.reject(error);
      }
      if (error.response?.status === 401 && !error?.config?._retry) {
        // error.config._retry = true;
        // if (TOKEN_REFRESH_PENDING) {
        //   // already token requested wait for the new token
        //   while (TOKEN_REFRESH_PENDING) {
        //     await new Promise((r) => setTimeout(r, 200));
        //   }
        //   return axios.request(error.config);
        // }
        // const refreshToken = localStorage.getItem('refreshToken');
        // TOKEN_REFRESH_PENDING = true;
        // const res = await refreshAccessToken(refreshToken);
        // if (res?.status === 200 && res?.data?.refresh && res?.data?.access) {
        //   await localStorage.setItem('refreshToken', res?.data?.refresh);
        //   await localStorage.setItem('token', res?.data?.access);
        //   TOKEN_REFRESH_PENDING = false;
        //   return axios.request(error.config);
        // }
        if (error.response?.data?.Msg) {
          enqueueSnackbar(error.response?.data?.Msg, {
            variant: 'error',
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(
            'Your session has expired. Please log in to continue using our services.',
            {
              variant: 'warning',
              preventDuplicate: true,
            }
          );
          setTimeout(() => {
            logout();
          }, 1000);
        }
        return Promise.reject(error);
      }
      if (error.response?.data?.Msg) {
        enqueueSnackbar(error.response?.data?.Msg, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
      return Promise.reject(error);
    }
  );
};

export const ApiUtils = {
  intializeAxios,
};
