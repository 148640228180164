// @ts-nocheck
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, HashRouter } from 'react-router-dom';
import { useTheme } from '@mui/material';
import UserConfirmationPrompt from '../components/Common/UserConfirmationPrompt';

const Login = lazy(() => import('../pages/Login'));
const Layout = lazy(() => import('../pages/Layout'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));

const PrivateRoute = ({ component: Component, isLoggedIn }) => {
  const url = window.location.href;
  if (!isLoggedIn) {
    localStorage.setItem('URL_TO_REDIRECT_AFTER_LOGIN', url);
  }
  return (
    <Route
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export const AppRoutes = () => {
  const isLoggedIn = useSelector((state) => state.app.auth.isLoggedIn);
  const { userConfirmationPrompt } = UserConfirmationPrompt(useTheme());
  return (
    <HashRouter getUserConfirmation={userConfirmationPrompt}>
      <React.Suspense fallback="">
        <Switch>
          <Route path="/login" component={Login} />

          <PrivateRoute path="/" isLoggedIn={isLoggedIn} component={Layout} />
          <Route
            path="*"
            name="Not Found"
            render={(props) => <PageNotFound {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};
